<template>
  <Card v-if="canShowDetails()">
    <template #title>
      {{ $t("video.payment.title") }}
    </template>
    <template #subtitle>
      {{
        $t("video.payment.description", {
          msg: balance.timeAvailableInMinutes(),
        })
      }}
    </template>
    <template #content>
      <h5>
        {{ $t("video.payment.content_title") }}
      </h5>
      <p>
        {{
          $t("video.payment.content_text1", {
            name: process.name,
            duration: process.video.durationInMinutes(),
          })
        }}
      </p>
      <p>{{ $t("video.payment.content_text2", { rest: restBalance }) }}</p>
      <p>{{ $t("video.payment.content_text3") }}</p>
      <p>{{ $t("video.payment.content_text4") }}</p>
    </template>
    <template #footer>
      <div class="p-grid p-nogutter p-justify-between">
        <i />
        <Button
          role="button"
          :label="nextStepLabel"
          icon="pi pi-angle-right"
          icon-pos="right"
          data-testid="button-payment"
          @click="nextStep"
        />
      </div>
    </template>
  </Card>
</template>

<script>
import { computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import Button from "primevue/button";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { FETCHING_LAST_BALANCE } from "../../store/modules/account/account.actions.type";
import { GET_LAST_BALANCE } from "../../store/modules/account/account.getters.type";
import { PAY_PROCESS } from "../../store/modules/process/process.actions.type";
import { GET_PROCESS_BY_ID } from "../../store/modules/process/process.getters.type";

export default {
  components: {
    Button,
    Card,
  },
  props: {
    processId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const process = computed(() =>
      store.getters[GET_PROCESS_BY_ID](props.processId)
    );
    const balance = computed(() => store.getters[GET_LAST_BALANCE]);
    const nextStepLabel = t("common.continue");
    const restBalance = computed(
      () =>
        balance.value?.timeAvailableInMinutes() -
        process.value?.video.durationInMinutes()
    );

    async function nextStep() {
      await store.dispatch(PAY_PROCESS, process.value.id);
      await router.push({
        name: "finished-video",
        params: { processId: process.value.id },
      });
    }

    onMounted(async () => {
      if (props.processId === null) {
        await router.push({ name: "upload-video" });
      }

      await store.dispatch(FETCHING_LAST_BALANCE);
    });

    function canShowDetails() {
      return !!(!!balance.value && !!process.value);
    }

    return {
      balance,
      process,
      nextStep,
      canShowDetails,
      restBalance,
      nextStepLabel,
    };
  },
};
</script>
